import { Interface } from '@alienkitty/space.js/three'
import { gsap } from 'gsap'

import { ProgressCanvas } from './ui/ProgressCanvas.js'
import { appState } from '../state/AppState'
import { Config } from '../config/Config.js'

// import JoustLogo from '../../../../public/assets/images/joust-logo-light2.svg'

export class PreloaderView extends Interface {
	constructor() {
		super('.preloader')

		// sizes
		this.sizes = {
			width: window.innerWidth,
			height: window.innerHeight
		}

		this.onResize()

		this.initHTML()
		this.initView()

		this.addListeners()
	}

	initHTML() {
		this.css({
			position: 'fixed',
			left: 0,
			top: 0,
			width: '100%',
			height: '100%',
			backgroundColor: 'var(--primaryColor)',
			zIndex: 100,
			pointerEvents: 'none'
		})
	}

	initView() {
		const circleSize = appState.isDesktop ? 666 : 300
		this.view = new ProgressCanvas(circleSize)
		this.view.css({
			position: 'absolute',
			left: '50%',
			top: '50%',
			marginLeft: -this.view.width / 2,
			marginTop: -this.view.height / 2
		})
		this.logo = new Image()
		// this.logo.src = 'assets/images/joust-logo-light2.svg'
		this.logo.src = 'assets/images/Joust_White.svg'
		this.logo.classList.add('preloader-logo')
		this.logo.classList.add('preloader-padding')
		this.logo.width = 143
		this.logo.height = 37
		this.add(this.logo)
		this.add(this.view)

		this.container = new Interface('.preloader-container')
		this.container.css({
			position: 'absolute',
			bottom: 0,
			padding: appState.isDesktop ? '32px' : '16px',
			width: '100%'
		})
		this.add(this.container)

		this.text = new Interface('.text')
		this.text.css({
			fontWeight: '500',
			fontSize: 16,
			lineHeight: 20,
			letterSpacing: 0.8,
			textAlign: 'right',
			textTransform: 'uppercase',
			opacity: 1,
			color: '#ffffff'
		})
		this.text.text(this.progressText(0))
		this.container.add(this.text)
	}

	addListeners() {
		this.view.events.on('complete', this.onComplete)
	}

	removeListeners() {
		this.view.events.off('complete', this.onComplete)
	}

	/**
	 * Event handlers
	 */

	progressText(num) {
		return `${num}/100`
	}

	onProgress = (e) => {
		// console.log(e)
		let progress = Math.round(e.progress * 100)
		progress = progress >= 100 ? 100 : progress
		this.text.text(this.progressText(progress))
		this.view.onProgress(e)
	}

	onComplete = () => {
		this.events.emit('complete')
	}

	onResize = () => {
		this.sizes.width = document.documentElement.clientWidth
		this.sizes.height = document.documentElement.clientHeight

		if (this.sizes.width > Config.BREAKPOINT) {
			appState.setIsDesktop(true)
		} else {
			appState.setIsDesktop(false)
		}
	}

	/**
	 * Public methods
	 */

	animateIn = () => {
		this.view.animateIn()
	}

	animateOut = () => {
		this.view.animateOut()
		gsap.to(this.logo, { duration: 0.2, opacity: 0, delay: 0 })
		this.container.tween({ opacity: 0 }, 1500, 'easeOutExpo')
		return this.tween({ height: 0 }, 1500, 'easeOutExpo', 500)
	}

	destroy = () => {
		this.removeListeners()

		return super.destroy()
	}
}
