import { observable, makeAutoObservable, action } from 'mobx'

class AppState {
	appIsReady = false
	isDesktop = false
	currentSectionIndex = ''
	currentSectionIsDark = true
	lastSectionIndex = ''

	constructor() {
		makeAutoObservable(this)
	}
	setAppIsReady() {
		this.appIsReady = true
	}
	setCurrentSectionIndex(index) {
		this.lastSectionIndex = this.currentSectionIndex
		this.currentSectionIndex = index
	}
	setCurrentSectionIsDark(bool) {
		this.currentSectionIsDark = bool
	}
	setIsDesktop(bool) {
		this.isDesktop = bool
	}
}

export const appState = new AppState()
