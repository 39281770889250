export class Config {
	static BREAKPOINT = 1024
	static colors = {
		bg: 'fafafa',
		shadow: 'bbbbbb',
		light: 'ffffff',
		shadow2: 'bbbbbb',
		light2: 'ffffff',
		flat1: 'e4dcff',
		flat2: 'cecece'
	}
}
