import { BufferLoader, MultiLoader, Stage } from '@alienkitty/space.js/three'

import { PreloaderView } from '../views/PreloaderView.js'
import { wait } from '@alienkitty/space.js/three'

export class Preloader {
	static init() {
		this.initStage()
		this.initView()
		this.initLoader()

		this.addListeners()
	}

	static initStage() {
		Stage.init()
	}

	static initView() {
		this.view = new PreloaderView()
		Stage.add(this.view)
	}

	static async initLoader() {
		this.view.animateIn()

		const bufferLoader = new BufferLoader()
		bufferLoader.loadAll([
			// 'models/home-hero.gltf',
			// 'models/diamonds1.gltf',
			// 'models/icons.gltf',
			// 'models/modular.gltf',
			// 'models/peace.gltf',
			// 'models/contact.gltf'
		])

		this.loader = new MultiLoader()
		this.loader.load(bufferLoader)
		this.loader.add(1)

		const { App } = await import('./App.js')
		this.loader.trigger(1)

		this.app = App

		await this.app.init(bufferLoader)
		this.loader.trigger(1)
	}

	static addListeners() {
		this.loader.events.on('progress', this.view.onProgress)
		this.view.events.on('complete', this.onComplete)
	}

	static removeListeners() {
		this.loader.events.off('progress', this.view.onProgress)
		this.view.events.off('complete', this.onComplete)
	}

	/**
	 * Event handlers
	 */

	static onComplete = async () => {
		this.removeListeners()

		this.loader = this.loader.destroy()
		// await wait(1000)

		this.app.start()
		await this.view.animateOut()
		this.view = this.view.destroy()
	}
}
